// eslint-disable-next-line import/prefer-default-export
export const selectCollections = state => state.collection;
export const selectCollectionById = (state, colId) =>
  state.collection.collections.find(col => col.id === colId) || {};

export const selectProjectById = (state, colId, projId) => {
  const collection = state.collection.collections.find(
    col => col.id === colId
  ) || { projects: [] };

  return collection.projects.find(proj => proj.id === projId) || {};
};
