/**
 * A refresh token promise holder
 * @typedef {Object} RefreshTokenHolder
 * @property {Promise} promise
 * @property {Boolean} inProgress
 */

/**
 * @type {RefreshTokenHolder}
 */
const refreshTokenHolder = {
  promise: undefined,
  inProgress: false,
};

export default refreshTokenHolder;
