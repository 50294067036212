import { extendTheme } from '@chakra-ui/react';

const direction = 'ltr';

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
  cssVarPrefix: 'chakra',
};
/* background: #2889A8;
 */

export const theme = extendTheme({
  direction,
  colors: {
    brand: {
      50: '#ddf9ff',
      100: '#bae6f4',
      200: '#94d4e8',
      300: '#6cc2de',
      400: '#46b1d3',
      500: '#2c97b9',
      600: '#1d7691',
      700: '#0f5469',
      800: '#003341',
      900: '#00131b',
    },
  },
  config,
});

// https://smart-swatch.netlify.app/#2889A8
