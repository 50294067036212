import { Progress } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../features/user/userSelectors';
import {
  setAccessToken,
  setInitialUserInfo,
  stopInitialFetch,
} from '../features/user/userSlice';
import { getAccessToken, getCompanyId } from '../services/Authentication';
import ErrorView from '../components/ErrorView';
import { errorLog, setBugsnagUser } from '../utils/general';

export default function AuthHandler({ children }) {
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();

  // we need this flag for some timeout.
  // Without it - application will return route(s) which redirect to login (for a fractions of seconds)
  // and we don't want that.

  useEffect(() => {
    // Before rendering pages, we need to make sure
    // that user was not previously logged in
    const accessToken = getAccessToken() || '';
    if (!!accessToken) {
      const companyId = getCompanyId();
      dispatch(setAccessToken({ accessToken }));
      const userInfoPromise = dispatch(setInitialUserInfo(companyId));
      userInfoPromise
        .then(userInfoRes => setBugsnagUser(userInfoRes.payload.user))
        .catch(errorLog);
    } else {
      // if user wasn't previously logged in
      dispatch(stopInitialFetch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userInfo.logoutErr || userInfo.errFetchUserInfo) {
    return (
      <ErrorView
        heading="System Error"
        body={userInfo.logoutErr || userInfo.errFetchUserInfo}
        onAction={() => window.location.reload()}
      />
    );
  }

  if (userInfo.isFetchingInitialUserInfo) {
    // Show a nice "Loader" when getting fetching events and user info
    return <Progress size="xs" isIndeterminate />;
  }
  return children;
}
