import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTestsOfProjectApi } from '../../services/test';
import {
  asyncGetTestSuite,
  asyncRemoveTestSuite,
  asyncSaveTestSuite,
} from '../../utils/actions';
import { TEST_STATUSES } from '../../utils/constants';
import { getUpdatedTestsByNewStatus, isAllDraft } from '../../utils/general';

const initialState = {
  tests: [],
  isAllDraft: false,
  isFetchingTests: false,
  errFetchTests: '',
  deletingTest: false,
  errInDeletingTest: '',
  selectionTestId: -1,
  editTest: {
    testId: -1, // remember steps (coming from extension) of test. if id persist it means user navigate from edit page during selection process
    steps: [],
  },
};

const sliceName = `test`;
const testSuiteAction = `lsTestSuite`;

export const getTestsOfProject = createAsyncThunk(
  `${sliceName}/getAllTestsOfProject`,
  async requestPayload => getTestsOfProjectApi(requestPayload)
);

export const getSelTestSuite = createAsyncThunk(
  `${sliceName}/get_${testSuiteAction}`,
  asyncGetTestSuite
);
export const removeSelTestSuite = createAsyncThunk(
  `${sliceName}/remove_${testSuiteAction}`,
  asyncRemoveTestSuite
);
export const saveSelTestSuite = createAsyncThunk(
  `${sliceName}/save_${testSuiteAction}`,
  asyncSaveTestSuite
);

export const counterSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setTestInProgress(state, { payload }) {
      const testIndex = state.tests.findIndex(t => t.id === payload);
      if (testIndex > -1) {
        state.tests[testIndex] = {
          ...state.tests[testIndex],
          lastRunStatus: TEST_STATUSES.IN_PROGRESS,
          lastRunTime: new Date().getTime(),
        };
        state.isAllDraft = false;
      }
    },
    setTest(state, { payload }) {
      const testIndex = state.tests.findIndex(t => t.id === payload.id);
      if (testIndex > -1) {
        state.tests[testIndex] = payload;
        state.isAllDraft = isAllDraft(state.tests);
      }
    },
    setTests(state, { payload }) {
      state.tests = payload;
      state.isAllDraft = isAllDraft(payload);
    },
    setUpdatedTestsStatus(state, { payload }) {
      state.tests = getUpdatedTestsByNewStatus(state.tests, payload);
    },
    removeTest(state, { payload }) {
      state.tests = state.tests.filter(test => test.id !== payload);
      state.isAllDraft = isAllDraft(state.tests);
    },
    addTestToList(state, { payload }) {
      state.tests.unshift(payload);
    },
    initEditSelection(state, { payload }) {
      state.editTest.testId = payload;
    },
    setEditSteps(state, { payload }) {
      state.editTest.steps = payload.map(st => ({
        ...st,
        isNew: 'isNew' in st ? st.isNew : true,
      }));
    },
    clearEditSelection(state) {
      state.editTest.testId = -1;
      state.editTest.steps = [];
    },
    setSelectionTestId(state, { payload }) {
      state.selectionTestId = payload;
    },
  },
  extraReducers: {
    // get tests of project
    [getTestsOfProject.fulfilled]: (state, { payload }) => {
      if (payload && Array.isArray(payload)) {
        state.tests = payload;
        state.isAllDraft = isAllDraft(payload);
      }
      state.isFetchingTests = false;
    },
    [getTestsOfProject.pending]: state => {
      state.errFetchTests = '';
      state.isFetchingTests = true;
    },
    [getTestsOfProject.rejected]: (state, action) => {
      state.errFetchTests = action?.error?.message || '';
      state.isFetchingTests = false;
    },
    [getSelTestSuite.fulfilled]: (state, { payload }) => {
      state.selectionTestId = payload ? payload.testId : -1;
    },
    [removeSelTestSuite.fulfilled]: state => {
      state.selectionTestId = -1;
    },
    [saveSelTestSuite.fulfilled]: (state, { payload }) => {
      state.selectionTestId = payload.testId;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTest,
  setTests,
  removeTest,
  initEditSelection,
  setEditSteps,
  clearEditSelection,
  setTestInProgress,
  setSelectionTestId,
  setUpdatedTestsStatus,
  addTestToList,
} = counterSlice.actions;

export default counterSlice.reducer;
