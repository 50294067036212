import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Alert,
  AlertIcon,
  AlertDescription,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../features/user/userSelectors';
import { createCompany } from '../features/user/userSlice';
import { validateEmail } from '../utils/general';

const validateForm = state =>
  state.name === '' ||
  state.cEmail === '' ||
  state.cFName === '' ||
  state.cLName === ''
    ? false
    : true;

export default function CreateCompanyModal({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const [formState, setFormState] = useState({
    name: '',
    cEmail: '',
    cFName: '',
    cLName: '',
  });

  const toast = useToast();

  const onChangeInput = e => {
    const { name, value } = e.target;
    setFormState(oldState => ({
      ...oldState,
      [name]: value,
    }));
  };

  const onSubmit = e => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const requestBody = {
      companyName: fd.get('name'),
      contactEmail: fd.get('cEmail'),
      contactFirstName: fd.get('cFName'),
      contactLastName: fd.get('cLName'),
    };

    dispatch(createCompany(requestBody))
      .then(() => {
        setFormState({
          name: '',
          cEmail: '',
          cFName: '',
          cLName: '',
        });
      })
      .then(onClose)

      .catch(e =>
        toast({
          title: e.message,
        })
      );
  };
  const { name, cEmail, cFName, cLName } = formState;

  const isValidEmail = validateEmail(cEmail);

  const isEmailErr = !!cEmail && !isValidEmail;

  const isDisableForm = !validateForm(formState) || !isValidEmail;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>Create Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isRequired>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                name="name"
                placeholder="Name"
                value={name}
                onChange={onChangeInput}
                required
              />
            </FormControl>
            <FormControl mt={3} isInvalid={isEmailErr} isRequired>
              <FormLabel htmlFor="cEmail">Contact Email</FormLabel>
              <Input
                id="cEmail"
                name="cEmail"
                placeholder="Email"
                type="email"
                value={cEmail}
                onChange={onChangeInput}
                required
              />
              {isEmailErr ? (
                <FormErrorMessage>invalid email.</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl mt={3} isRequired>
              <FormLabel htmlFor="cFName">Contact First Name</FormLabel>
              <Input
                id="cFName"
                name="cFName"
                placeholder="First Name"
                value={cFName}
                onChange={onChangeInput}
                required
              />
            </FormControl>
            <FormControl mt={3} isRequired>
              <FormLabel htmlFor="cLName">Contact Last Name</FormLabel>
              <Input
                id="cLName"
                name="cLName"
                placeholder="Last Name"
                value={cLName}
                onChange={onChangeInput}
                required
              />
            </FormControl>

            {userInfo.errCreatingCompany ? (
              <Alert status="error" mt={3} mb={3}>
                <AlertIcon />
                <AlertDescription>
                  {userInfo.errCreatingCompany}
                </AlertDescription>
              </Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={onClose}
              disabled={userInfo.isCreatingCompany}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              mr={3}
              type="submit"
              isLoading={userInfo.isCreatingCompany}
              disabled={isDisableForm}
            >
              Create Organization
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
