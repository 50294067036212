export const WIN_MSG_ACTIONS = {
  START_SELECTION: 'START_SELECTION',
  SAVE_STEP: 'SAVE_STEP',
  COMPLETE_SELECTION: 'COMPLETE_SELECTION',
  REQ_TAB_ID: 'REQ_TAB_ID',
  TAB_PLUGIN_INFO_REQ: 'TAB_PLUGIN_INFO_REQ',
  TAB_PLUGIN_INFO_RECEIVED: 'TAB_PLUGIN_INFO_RECEIVED',
  // RELOAD_WEB_APP: 'RELOAD_WEB_APP',
  CLEAR_SEL_TEST_SUITE: 'CLEAR_SEL_TEST_SUITE',
  EDIT_CANCELLED: 'EDIT_CANCELLED',
  SEND_ERROR: 'SEND_ERROR',
};

export const LS_KEY = {
  testSuite: 'testSuite',
};

export const MSG_APP_KEY = 'IHI';

export const APP_NAME = `Turino`;

export const APP_TYPE = `${APP_NAME}_Web_App`;

export const PORT_CONN_NAME = `${MSG_APP_KEY}_${APP_NAME}`;

export const HEADER_HEIGHT = 16;
export const SIDE_BAR_OPENED_WIDTH = 60;
export const SIDE_BAR_CLOSED_WIDTH = 8;
export const APP_HEADER_Z_INDEX = 200;
export const NO_PLUGIN_Z_INDEX = APP_HEADER_Z_INDEX + 1;

export const TEST_EXE_STATUS = {
  INIT: 'INIT',
};

export const TEST_STATUSES = {
  DRAFT: 'DRAFT',
  CREATED: 'CREATED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  COMPLETED: 'COMPLETED', // TODO: find where it is being used.
  TERMINATED: 'TERMINATED',
  PREPARING: 'PREPARING',
  IN_QUEUED: 'IN_QUEUED',
  UPDATED: 'UPDATED',
};

export const SELECTION_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  TERMINATED: 'TERMINATED',
};

export const STATUS_KEYS = {
  DRAFT: 'Draft',
  CREATED: 'Created',
  IN_PROGRESS: 'In Progress',
  FAILED: 'Failed',
  SUCCESS: 'Success',
  COMPLETED: 'Completed',
  TERMINATED: 'Terminated',
  PREPARING: 'Preparing',
  IN_QUEUED: 'In Queue',
  UPDATED: 'Updated',
};

export const HEADER_KEYS = {
  ACCESS_TOKEN_KEY: 'access_token_qta',
  REFRESH_TOKEN_KEY: 'refresh_token_qta',
  // ACCESS_KEY: 'atEvent-portal',
  CLIENT_ID: 'clientId',
  SECRET_KEY: 'secret',
};

export const COMPANY_ID_KEY = 'comp_id_qta';

export const TABLE_KEYS = {
  TESTS_OF_PROJ: 'TESTS_OF_PROJ',
};

export const NAV = {
  ACTIVE_ITEM: 'activeProjNavItem',
  LIST: 'projectNavList',
};

export const URL_PROTOCOL_HTTPS = 'https://';
export const URL_PROTOCOL_HTTP = 'http://';

export const AIR_TABLE_URL = 'https://airtable.com';

export const AIR_TABLE_ERR = {
  NOT_FOUND: 'Data source not found',
  'Authentication required': 'Invalid API Key',
};

export const SOURCE_TYPES = {
  AIR_TABLE: 'AIR_TABLE',
  CSV: 'CSV',
};
// export const URL_REGEX =
// /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

export const URL_REGEX =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const USER_ROLES = {
  // keep the order
  SUPER_ADMIN: 'SUPER_ADMIN',
  ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
  TEAM_MANAGER: 'TEAM_MANAGER',
  TEAM_MEMBER: 'TEAM_MEMBER',
};

export const USER_ROLES_LIST = Object.values(USER_ROLES);

export const USER_ROLES_LIST_OPTIONS = [
  {
    id: 1,
    roleName: 'Super Admin',
    code: USER_ROLES.SUPER_ADMIN,
  },
  {
    id: 2,
    roleName: 'Organization Admin',
    code: USER_ROLES.ORGANIZATION_ADMIN,
  },
  {
    id: 3,
    roleName: 'Team Manager',
    code: USER_ROLES.TEAM_MANAGER,
  },
  {
    id: 4,
    roleName: 'Team Member',
    code: USER_ROLES.TEAM_MEMBER,
  },
];

export const ACCOUNT_TYPES = {
  MOBILE_NUMBER: 'MOBILE_NUMBER',
  EMAIL: 'EMAIL',
  SOCIAL_ID: 'SOCIAL_ID',
};

export const MODAL_NAMES = {
  COMPANY_SWITCH: 'COMPANY_SWITCH',
};

export const PLUGIN_STATUSES = {
  NOT_INSTALLED: 'NOT_INSTALLED',
  INSTALLED: 'INSTALLED',
  // OUTDATED: 'OUTDATED', // when currently installed plugin is outdated
  DEFERRED: 'DEFERRED', // when application reload required
  // QUERIED: 'QUERIED', // when requested for plugin check
  INIT: 'INIT', // initial state
};

export const STORE_LINK = `https://chrome.google.com/webstore/detail/turino/khannnjanopdophappigfnenkiepebek`;

export const ASSERTION_TYPES = {
  text: 'TEXT',
  link: 'LINK',
};

export const BugsnagMetaKeys = {
  curComp: 'curComp',
  curRole: 'curRole',
};
