import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { asyncLogout, asyncSetCompanyId } from '../../services/Authentication';
import { createCompanyApi } from '../../services/company';
import {
  createUserApi,
  getFormattedUserInfoApi,
} from '../../services/UserInfo';
import { MODAL_NAMES } from '../../utils/constants';

const initialState = {
  userRes: undefined,
  isFetchingInitialUserInfo: true,
  isLoggedIn: false,
  user: undefined,
  errFetchUserInfo: '',
  tokens: {
    access: '',
  },
  logoutErr: '',
  isCreatingCompany: false,
  errCreatingCompany: '',
  isCreatingUser: false,
  errCreatingUser: '',
  [MODAL_NAMES.COMPANY_SWITCH]: false,
};

export const sliceName = `userInfo`;

export const setInitialUserInfo = createAsyncThunk(
  `${sliceName}/setInitialUserInfo`,
  getFormattedUserInfoApi
);

export const logOutUserInfo = createAsyncThunk(
  `${sliceName}/logOutUserInfo`,
  async () => asyncLogout()
);

export const createCompany = createAsyncThunk(
  `${sliceName}/createCompany`,
  createCompanyApi
);

export const createUser = createAsyncThunk(
  `${sliceName}/createUser`,
  createUserApi
);

export const setUserCompany = createAsyncThunk(
  `${sliceName}/setUserCompany`,
  asyncSetCompanyId
);

export const userSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setAccessToken(state, { payload }) {
      state.tokens.access = payload.accessToken;
    },
    // called when user authenticate with login page
    setUserLogin(state, { payload }) {
      state.userRes = payload.userRes;
      state.isLoggedIn = true;
      state.user = payload.user;
      state.tokens = {
        access: payload.token.access_token,
      };
      state.logoutErr = '';
    },
    stopInitialFetch(state) {
      state.isFetchingInitialUserInfo = false;
    },
    setModalState(state, { payload }) {
      state[payload.name] = payload.isOpen;
    },
  },
  extraReducers: {
    // setInitialUserInfo
    [setInitialUserInfo.fulfilled]: (state, { payload }) => {
      state.userRes = payload.userRes;
      state.user = payload.user;
      state.isLoggedIn = true;
      state.isFetchingInitialUserInfo = false;
    },
    [setInitialUserInfo.rejected]: (state, action) => {
      state.errFetchUserInfo = action?.error?.message || '';
      state.isFetchingInitialUserInfo = false;
    },
    // logOutUserInfo
    [logOutUserInfo.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = undefined;
      state.userRes = undefined;
      state.tokens = {
        access: '',
      };
      state.logoutErr = '';
    },
    [logOutUserInfo.rejected]: (state, action) => {
      state.logoutErr =
        action?.error?.message || `Attempt of 'Sign Out' is failed.`;
    },
    // createCompany
    [createCompany.fulfilled]: (state, { payload }) => {
      state.errCreatingCompany = '';
      state.userRes.companies.push(payload);
      state.isCreatingCompany = false;
    },
    [createCompany.pending]: (state, action) => {
      state.isCreatingCompany = true;
    },
    [createCompany.rejected]: (state, action) => {
      state.errCreatingCompany = action?.error?.message || '';
      state.isCreatingCompany = false;
    },
    // createUser
    [createCompany.fulfilled]: state => {
      state.errCreatingUser = '';
      state.isCreatingUser = false;
    },
    [createCompany.pending]: state => {
      state.isCreatingUser = true;
    },
    [createCompany.rejected]: (state, action) => {
      state.errCreatingUser = action?.error?.message || '';
      state.isCreatingUser = false;
    },
    // setUserCompany
    [setUserCompany.fulfilled]: (state, { payload }) => {
      state.user.curComp = payload;
      state[MODAL_NAMES.COMPANY_SWITCH] = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setModalState, setUserLogin, setAccessToken, stopInitialFetch } =
  userSlice.actions;

export default userSlice.reducer;
