import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollections } from '../features/collection/collectionSelectors';
import { createCollection } from '../features/collection/collectionSlice';
import { selectUser } from '../features/user/userSelectors';

export default function CreateCollectionModal({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { isCreatingCollection, errCreatingCollection } =
    useSelector(selectCollections);
  const user = useSelector(selectUser);

  const toast = useToast();
  const onSubmit = e => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const requestBody = {
      name: fd.get('name'),
      description: fd.get('desc'),
      // code: fd.get('code'),
    };

    dispatch(
      createCollection({
        requestBody,
        companyId: user?.curComp?.id,
      })
    )
      .then(onClose)
      .catch(e =>
        toast({
          title: e.message,
        })
      );
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>Create Team</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                // ref={initialRef}
                id="name"
                name="name"
                placeholder="Name"
                required
              />
            </FormControl>
            {/* <FormControl mt={3}>
              <FormLabel htmlFor="code">Code</FormLabel>
              <Input
                id="code"
                name="code"
                placeholder="Code"
                required
              />
            </FormControl> */}
            <FormControl mt={3}>
              <FormLabel htmlFor="desc">Description</FormLabel>
              <Input
                // ref={initialRef}
                id="desc"
                name="desc"
                placeholder="Description"
                required
              />
            </FormControl>
            {errCreatingCollection ? (
              <Alert status="error" mt={3} mb={3}>
                <AlertIcon />
                <AlertDescription>{errCreatingCollection}</AlertDescription>
              </Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} disabled={isCreatingCollection}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              mr={3}
              type="submit"
              isLoading={isCreatingCollection}
            >
              Create Team
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
