import HttpReq from '../utils/HttpReq';

const routeName = `/teams`;

/**
 * @description get collections(Teams) all or by company
 */
export const getCollectionsApi = companyId =>
  HttpReq.get(`${routeName}${companyId ? '/company/' + companyId : ''}`);

export const createCollectionApi = ({ requestBody, companyId }) =>
  HttpReq.post(`${routeName}/company/${companyId}`, requestBody);

export const deleteCollectionApi = (companyId, collectionId) =>
  HttpReq.delete(
    `${routeName}/company/${companyId}/delete`,
    [collectionId],
    undefined,
    {
      postJSON: true,
      preJSON: true,
    }
  );

export const forceDeleteCollectionApi = collectionId =>
  HttpReq.delete(`${routeName}/force-delete`, [collectionId], undefined, {
    postJSON: true,
    preJSON: true,
  });
