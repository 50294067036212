import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getCollections,
  setCollections,
} from '../features/collection/collectionSlice';
import { selectTestSlice } from '../features/test/testSelectors';
import {
  clearEditSelection,
  getSelTestSuite,
  removeSelTestSuite,
  setEditSteps,
  setSelectionTestId,
  setTest,
} from '../features/test/testSlice';
import { selectUi } from '../features/ui/uiSelector';
import { setPluginStatus } from '../features/ui/uiSlice';
import { selectUserInfo } from '../features/user/userSelectors';
import { createStepApi } from '../services/steps';
import { getTestsOfProjectApi } from '../services/test';
import {
  removeTestSuite,
  requestPluginInfo,
  warnEditDiscard,
} from '../utils/actions';
import {
  MSG_APP_KEY,
  PLUGIN_STATUSES,
  WIN_MSG_ACTIONS,
} from '../utils/constants';
import { generateSteps, reportPluginErr } from '../utils/general';

function promptBeforeAppLeave(event) {
  event.returnValue =
    'Are you really want to leave?\nElement selection is pending.';
}

/**
 * @description register listener
 */
export default function AppWatchMan() {
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = useToast();
  const userInfo = useSelector(selectUserInfo) || {};
  const ui = useSelector(selectUi);
  const { editTest, selectionTestId } = useSelector(selectTestSlice);
  const { isLoggedIn, user } = userInfo;

  useEffect(() => {
    if (selectionTestId !== -1) {
      window.addEventListener('beforeunload', promptBeforeAppLeave);
    } else {
      window.removeEventListener('beforeunload', promptBeforeAppLeave);
    }
    return () => {
      window.removeEventListener('beforeunload', promptBeforeAppLeave);
    };
  }, [selectionTestId]);

  useEffect(() => {
    window.addEventListener('unload', removeTestSuite);
  }, []);

  useEffect(() => {
    if (editTest.testId !== -1 && +editTest.testId === +selectionTestId) {
      warnEditDiscard(editTest.testId);
      dispatch(clearEditSelection());
      dispatch(setSelectionTestId(-1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /* const onAddStep = payload => {
    createStepApi([payload.newStep])
      // .then(() => {
      //   dispatch(getTestsOfProject(payload.projId));
      // })
      .catch(err => {
        devLog(err);
        toast({
          title: err.message,
          status: 'error',
        });
      });
  }; */
  const onCompleteSelection = async testSuite => {
    dispatch(removeSelTestSuite());
    if (testSuite.update) {
      dispatch(setEditSteps(testSuite.steps));
      return;
    }

    if (testSuite.steps.length === 0) {
      return;
    }

    try {
      await createStepApi(generateSteps(testSuite.steps, testSuite.testId));
      toast({
        title: 'Test created',
      });
      const updatedTestRes = await getTestsOfProjectApi({
        projId: testSuite.projId,
        testIdList: [testSuite.testId],
      });
      dispatch(setTest(updatedTestRes[0]));
    } catch (e) {
      toast({
        title: e.message,
        status: 'error',
      });
    }
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      event => {
        const {
          src,
          action,
          payload,
          // from
        } = event.data || {};
        // handle only ihi chrome plugin messages
        if (src !== MSG_APP_KEY) {
          return;
        }
        switch (action) {
          /* case WIN_MSG_ACTIONS.SAVE_STEP: {
            onAddStep(payload);
            break;
          } */
          case WIN_MSG_ACTIONS.SEND_ERROR: {
            reportPluginErr(payload.error, payload.meta);
            break;
          }
          case WIN_MSG_ACTIONS.TAB_PLUGIN_INFO_RECEIVED: {
            dispatch(
              setPluginStatus({
                pluginStatus: PLUGIN_STATUSES.INSTALLED,
                webAppTabId: payload.webAppTabId,
              })
            );
            break;
          }
          case WIN_MSG_ACTIONS.COMPLETE_SELECTION: {
            onCompleteSelection(payload);
            break;
          }
          case WIN_MSG_ACTIONS.CLEAR_SEL_TEST_SUITE: {
            dispatch(removeSelTestSuite());
            break;
          }
          default:
            break;
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCollections(user?.curComp?.id));
      dispatch(getSelTestSuite());
    } else {
      dispatch(setCollections([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, user?.curComp?.id]);

  useEffect(() => {
    if (ui.pluginStatus === PLUGIN_STATUSES.INIT) {
      setTimeout(requestPluginInfo, 1_000);
    }
  }, [ui.pluginStatus]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // save plugin status as not installed if plugin status (init) not change in 8 seconds
      if (ui.pluginStatus === PLUGIN_STATUSES.INIT) {
        dispatch(
          setPluginStatus({
            pluginStatus: PLUGIN_STATUSES.NOT_INSTALLED,
            webAppTabId: -1,
          })
        );
      }
    }, 8_000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ui.pluginStatus]);
  return <div className="AppWatchMan" />;
}
