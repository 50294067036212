import { Box, Button, Text } from '@chakra-ui/react';

export default function Error({ onAction, heading, body }) {
  return (
    <Box>
      <Text fontSize="lg">{heading}</Text>
      <Text color="red">{body}</Text>
      <Button onClick={onAction} variant="outline">
        Try Next
      </Button>
    </Box>
  );
}
