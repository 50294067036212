/**
 * @description Map Headers from srcConfig to toConfig
 * @param {Object} srcConfig Source options object
 * @param {Headers} srcConfig.headers Source Header object
 * @param {Object} toConfig Destination options object
 * @param {Headers} toConfig.headers destination Header object
 */
export function setInitHeaders(srcConfig, toConfig) {
  if (srcConfig && srcConfig.headers) {
    if (!toConfig.headers) {
      // eslint-disable-next-line no-param-reassign
      toConfig.headers = new Headers();
    }
    srcConfig.headers.forEach((value, key) => toConfig.headers.set(key, value));
  }
}

/**
 * @description get URL encoded query string
 * @param {Object} obj object with keys
 * @returns {String}
 */
export const getBodyParams = obj => new URLSearchParams(obj).toString();

/**
 * @description set request body and headers
 * @param {any} payload payload
 * @param {Object} reqOptions Request option (init)
 * @param {Headers} reqOptions.header Headers
 * @param {any} reqOptions.body body
 * @param {Object} allFeatures Post Feature
 * @param {Boolean} allFeatures.postURLEnc Url encoded
 * @param {Boolean} allFeatures.multiPartForm multi part form data
 * @param {Boolean} allFeatures.hasFormData flag to use provided form data
 * @param {Boolean} allFeatures.preJSON JSON
 */
export const setPostBodyHeaders = (payload, reqOptions, allFeatures) => {
  if (allFeatures.postURLEnc) {
    reqOptions.headers.append(
      'Content-type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    );
    // eslint-disable-next-line no-param-reassign
    reqOptions.body = getBodyParams(payload);
    // form data multipart/form-data
  } else if (allFeatures.multiPartForm) {
    if (!allFeatures.hasFormData) {
      const formDataPayload = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        formDataPayload.append(key, value);
      });
      // eslint-disable-next-line no-param-reassign
      reqOptions.body = formDataPayload;
    } else {
      // eslint-disable-next-line no-param-reassign
      reqOptions.body = payload;
    }
  } else if (allFeatures.preJSON) {
    reqOptions.headers.append('Content-type', 'application/json');
    // eslint-disable-next-line no-param-reassign
    reqOptions.body = JSON.stringify(payload);
  }
};
