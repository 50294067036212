import { Flex, Spinner } from '@chakra-ui/react';
import { Suspense } from 'react';

export default function PageLoader({ children }) {
  return (
    <Suspense
      fallback={
        <Flex justify="center" align="center" height="40vh">
          <Spinner size="xl" color="brand.500" />
        </Flex>
      }
    >
      {children}
    </Suspense>
  );
}
