import HttpReq from '../utils/HttpReq';
import { getPreAuthHeader, setAuthTokens } from './Authentication';
import { getFormattedUserInfoApi } from './UserInfo';

/**
 * Authenticates a user and stores the access/refresh tokens in a cookie
 * @param {Object} formData  - A FormData object
 * @returns {Promise} user info
 */
const loginApi = async payload => {
  const path = '/security/oauth/token';
  const options = {
    headers: getPreAuthHeader(),
  };
  const features = {
    multiPartForm: true,
    postJSON: true,
  };
  const token = await HttpReq.post(path, payload, options, features);
  setAuthTokens(token);
  const userRes = await getFormattedUserInfoApi();
  return {
    ...userRes,
    token,
  };
};

export default loginApi;
