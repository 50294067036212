export const defaultError = {
  error: null,
  error_description: `Something went wrong`,
};
/**
 * A fetchReq features
 * @typedef {Object} Feature
 * @property {Boolean} features.postJSON parse response in JSON
 * @property {Boolean} features.postText parse response in text
 */

/**
 * @description Success handler
 * @param {any} res -any network response
 * @param {Feature} features - features to include
 * @returns {Promise} response of async call
 */
export const onHttpReqSuccess = (res, features) => {
  const { postJSON, postText } = features || {};
  if (postJSON) {
    return res.json();
  }
  if (postText) {
    return res.text();
  }
  return res;
};

/**
 *
 * @param {string} path path of url
 * @param {Object} options fetch options
 * @param {Feature} [features={postJSON: true}] - features to include
 * @example
 * fetchReq('https://www.domain.com/api', {method: 'get'})
 * @example
 * fetchReq('https://www.domain.com/api',
 *  {
 *    method: 'post',
 *    headers: new Headers({Authorization: 'Basic xxx:xxx'})
 *  },
 *  {postText: true}
 * )
 * @returns {Promise} Fetch response
 */
async function fetchReq(url, options, features) {
  const reqFeatures = features || {
    postJSON: true,
  };
  return fetch(url, options).then(res => {
    const statusCode = res.status;
    if (!res.ok || statusCode >= 400) {
      return res
        .json()
        .then(errRes => {
          let msg = defaultError.error_description;
          if (errRes && errRes.error_description) {
            msg = errRes.error_description;
          } else if (errRes.message) {
            msg = errRes.message;
          }
          const error = new Error(msg);
          error.response = errRes || defaultError;
          error.config = options;
          error.url = url;
          error.features = reqFeatures;
          error.status = statusCode;
          throw error;
        })
        .catch(unknownError => {
          // not set in then else create error
          if (!unknownError.response) {
            const error = new Error(defaultError.error_description);
            error.error = unknownError;
            error.response = defaultError;
            error.config = options;
            error.url = url;
            error.features = reqFeatures;
            error.status = statusCode;
            throw error;
          }
          throw unknownError;
        });
    }
    return onHttpReqSuccess(res, reqFeatures);
  });
}

export default fetchReq;
