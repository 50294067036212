import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectIsAuthenticated } from '../features/user/userSelectors';

/**
 * @description prevent access with non-auth state
 */
const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsAuthenticated);
  // const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Navigate
        // to={`/login${location ? `?from=${location?.pathname}` : ''}`}
        to="/login"
        replace
        // state={{ from: location }}
      />
    );
  }
  return children;
};

export default ProtectedRoute;
