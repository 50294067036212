import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import AppLayout from './AppLayout';
import ProtectedRoute from './auth/protectedRoute';
import NonAuthRoute from './auth/nonAuthRoute';
import PageLoader from './components/PageLoader';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const Collection = lazy(() => import('./pages/collection'));
const ProjectTests = lazy(() => import('./pages/project'));
const TestEdit = lazy(() => import('./pages/test/Edit'));
const NotFound = lazy(() => import('./pages/NotFound'));

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <NonAuthRoute>
            <Login />
          </NonAuthRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={
            <PageLoader>
              <Dashboard />
            </PageLoader>
          }
        />
        <Route
          path="dashboard"
          element={
            <PageLoader>
              <Dashboard />
            </PageLoader>
          }
        />
        {/* <Route path="/collections/*" element={<Collections />} /> */}
        <Route
          path="collections/:colId"
          element={
            <PageLoader>
              <Collection />
            </PageLoader>
          }
        />
        <Route
          path="collections/:colId/projects/:projId/tests"
          element={
            <PageLoader>
              <ProjectTests />
            </PageLoader>
          }
        />
        <Route
          path="collections/:colId/projects/:projId/tests/:testId/edit"
          element={
            <PageLoader>
              <TestEdit />
            </PageLoader>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <PageLoader>
            <NotFound />
          </PageLoader>
        }
      />
    </Routes>
  );
}
