import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  useColorModeValue,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { setUserLogin } from '../../features/user/userSlice';
import loginApi from '../../services/Login';
import { devLog, isSuperAdmin, setBugsnagUser } from '../../utils/general';
import { setCompanyId } from '../../services/Authentication';
import CompanySelectorInputs from '../../molecules/CompanySelectorInputs';

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [isInProg, setIsInProg] = useState(false);
  const [error, setError] = useState('');
  const [userLoginRes, setUserLoginRes] = useState();
  const [companies, setCompanies] = useState([]);
  const [selComp, setSelComp] = useState(null);
  const dispatch = useDispatch();

  const onSetCompany = () => {
    const payload = {
      ...userLoginRes,
      user: {
        ...userLoginRes.user,
        curComp: selComp,
      },
      userRes: {
        ...userLoginRes.userRes,
        companies,
      },
    };
    setCompanyId(selComp.id);
    setBugsnagUser(payload.user);
    dispatch(setUserLogin(payload));
  };

  const onSubmit = async e => {
    e.preventDefault();
    try {
      setIsInProg(true);
      const fd = new FormData(e.target);
      const username = fd.get('email');
      const password = fd.get('password');
      const loginRes = await loginApi({
        username,
        password,
        grant_type: 'password',
      });

      /* we can change the condition from super admin to check user.curComp (loginRes.user.curRole === undefined) to  allow any user to pick companies from the list (loginRes.userRes.companies) */
      if (isSuperAdmin(loginRes.user.curRole)) {
        setUserLoginRes(loginRes);
        setCompanies(loginRes.userRes.companies);
        setIsInProg(false);
      } else {
        setIsInProg(false);
        dispatch(setUserLogin(loginRes));
      }
    } catch (err) {
      devLog(err);
      setError(err.message);
      setIsInProg(false);
    }
  };
  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        as="form"
        name="signIn"
        onSubmit={onSubmit}
        spacing={4}
        w={'full'}
        maxW={'lg'}
        maxH="lg"
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Sign in
        </Heading>
        {userLoginRes && companies.length ? (
          <>
            <CompanySelectorInputs
              companies={companies}
              selComp={selComp}
              onSelectedItemChange={setSelComp}
              onSetCompany={onSetCompany}
            />
          </>
        ) : (
          <>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                placeholder="your-email@example.com"
                _placeholder={{ color: 'gray.500' }}
                type="email"
                name="email"
                autoComplete="username"
              />
            </FormControl>

            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  name="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                />
                <InputRightElement h={'full'}>
                  <IconButton
                    onClick={() =>
                      setShowPassword(showPassword => !showPassword)
                    }
                    variant="ghost"
                    icon={showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    fontSize="2xl"
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Stack spacing={6}>
              {error ? (
                <Alert status="error">
                  <AlertIcon />
                  {error}
                </Alert>
              ) : null}
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
              >
                {/* <Checkbox>Remember me</Checkbox> */}
                <Link color={'blue.400'}>Forgot password?</Link>
              </Stack>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                type="submit"
                isLoading={isInProg}
              >
                Submit
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </Flex>
  );
}
