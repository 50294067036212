import {
  devLog,
  downloadBlob,
  getFileName,
  hasFailedSteps,
} from '../utils/general';
import HttpReq from '../utils/HttpReq';

const routeName = `/tests`;

/* const transformTestData = tests => {
  tests.forEach(t => {
    // const { steps } = t;
    // hashmap
    // const stepMap = {};
    // steps.forEach(s => (stepMap[s.id] = s.name));

    t.lastRunPages &&
      t.lastRunPages.forEach(lrp => {
        lrp.testId = t.id;
        lrp.executionSteps &&
          lrp.executionSteps.forEach(es => {
            // remove null/undefined if any
            es.actualText = es.actualText || '';
            es.assertedText = es.assertedText || '';

            // add step name
            // es.stepName = stepMap[es.stepId] || '';
          });
      });
  });
  return tests;
}; */

const addTestIdToLastRunPages = tests => {
  tests.forEach(t => {
    t.lastRunPages &&
      t.lastRunPages.forEach(lrp => {
        lrp.testId = t.id;
      });
  });
  return tests;
};

export const getResultOfTestApi = (projId, testId) =>
  HttpReq.get(`${routeName}/project/${projId}/result/${testId}`);

export const getTestResult = async (projId, testId) => {
  const resRes = await getResultOfTestApi(projId, testId);
  const stepMap = {};
  resRes.steps.forEach(s => (stepMap[s.id] = s.name));
  resRes.lastRunPages.forEach(lrp => {
    lrp.testId = testId;
    lrp.executionSteps &&
      lrp.executionSteps.forEach(es => {
        // remove null/undefined if any
        es.actualText = es.actualText || '';
        es.assertedText = es.assertedText || '';
        // add step name
        es.stepName = stepMap[es.stepId] || '';
      });
  });
  resRes.failedPages = resRes.lastRunPages.filter(hasFailedSteps);
  return resRes;
};

export const getTestsOfProjectApi = async ({ projId, testIdList = [] }) =>
  HttpReq.post(`${routeName}/project/${projId}/result`, testIdList).then(
    addTestIdToLastRunPages
  );

export const createTestApi = payload => HttpReq.post(routeName, payload);

export const getTestApi = testId => HttpReq.get(`${routeName}/${testId}`);

export const updateTestApi = payload =>
  HttpReq.put(routeName, payload).then(() => getTestApi(payload.id));

export const reRunTests = testId =>
  HttpReq.post(`${routeName}/rerun`, {
    testId,
  });

export const reRunTestsOfProject = projectId =>
  HttpReq.post(`${routeName}/rerun-all`, {
    projectId,
  });

export const deleteTestApi = testId =>
  HttpReq.delete(`${routeName}/delete`, [testId], undefined, {
    postJSON: true,
    preJSON: true,
  });

export const downloadAllScreenShotApi = async testId => {
  try {
    const res = await HttpReq.get(
      `${routeName}/${testId}/screenshots/all`,
      undefined,
      {}
    );

    let fileName = `test${testId}.zip`;
    try {
      fileName = getFileName(res.headers.get('content-disposition'));
    } catch (error) {
      devLog(error);
    }

    const blob = await res.blob();
    downloadBlob(blob, fileName);
  } catch (err) {
    devLog(err);
  }
};

export const downloadSingleScreenShotApi = async imgUrl => {
  try {
    const res = await fetch(imgUrl);
    let fileName = imgUrl;
    try {
      fileName = getFileName(res.headers.get('content-disposition'));
    } catch (error) {
      devLog(error);
    }
    const buffer = await res.arrayBuffer();
    downloadBlob(buffer, fileName);
  } catch (error) {
    devLog(error);
  }
};
