/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PLUGIN_STATUSES } from '../../utils/constants';

const sliceName = `ui`;

const initialState = {
  pluginStatus: PLUGIN_STATUSES.INIT,
  webAppTabId: -1,
};

const ui = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPluginStatus(state, { payload }) {
      state.pluginStatus = payload.pluginStatus;
      state.webAppTabId = payload.webAppTabId;
    },
  },
});

export const { setPluginStatus } = ui.actions;

export default ui.reducer;
