import {
  List,
  ListItem,
  ListIcon,
  IconButton,
  Flex,
  Box,
  Tooltip,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { BsListNested } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { TiBusinessCard } from 'react-icons/ti';
import { FiUsers, FiUserPlus } from 'react-icons/fi';
import CreateCompanyModal from './CreateCompanyModal';
import CreateCollectionModal from './CreateCollectionModal';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/user/userSelectors';
import { isSuperAdmin } from '../utils/general';
import CreateUserModal from './CreateUserModal';

const ShortCutListItem = ({ icon, text, onClickAdd, tooltip, disableAdd }) => (
  <ListItem
    p={1}
    display="flex"
    justifyContent="space-between"
    _hover={{
      bg: 'gray.200',
    }}
  >
    <Box>
      <ListIcon as={icon} />
      {text}
    </Box>
    <Tooltip label={tooltip}>
      <IconButton
        aria-label={tooltip}
        size="xs"
        isRound
        _hover={{
          bg: 'white',
        }}
        onClick={onClickAdd}
        icon={<FiPlus />}
        disabled={disableAdd}
      />
    </Tooltip>
  </ListItem>
);

export default function SidebarShortcuts() {
  const createCompModalConf = useDisclosure();
  const createColModalConf = useDisclosure();
  const createUserModalConf = useDisclosure();

  const user = useSelector(selectUser);

  const hasNotComp = !user?.curComp;
  const isSuperAdminRole = isSuperAdmin(user?.curRole || {});

  return (
    <>
      <List spacing={3} p={2}>
        {isSuperAdminRole ? (
          <ShortCutListItem
            icon={TiBusinessCard}
            text="Organization"
            tooltip="Create a new organization"
            onClickAdd={createCompModalConf.onOpen}
          />
        ) : null}
        {isSuperAdminRole ? (
          <ShortCutListItem
            icon={FiUserPlus}
            text="Users"
            tooltip="Create a new user"
            onClickAdd={createUserModalConf.onOpen}
          />
        ) : null}
        <ShortCutListItem
          icon={FiUsers}
          text="Teams"
          tooltip="Create a new team"
          onClickAdd={createColModalConf.onOpen}
          disableAdd={hasNotComp}
        />
      </List>
      <Flex ml={1} my={2} alignItems="center">
        <BsListNested />
        <Text ml={2}>Teams</Text>
      </Flex>
      <CreateUserModal
        isOpen={createUserModalConf.isOpen}
        onClose={createUserModalConf.onClose}
      />
      <CreateCompanyModal
        isOpen={createCompModalConf.isOpen}
        onClose={createCompModalConf.onClose}
      />
      <CreateCollectionModal
        isOpen={createColModalConf.isOpen}
        onClose={createColModalConf.onClose}
      />
    </>
  );
}
