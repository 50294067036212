import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserCompany } from '../features/user/userSlice';
import { setBugsnagUserComp } from '../utils/general';
import CompanySelectorInputs from './CompanySelectorInputs';

export default function UserCompanySelectorModal({
  isOpen,
  onClose,
  companies,
  userSelComp,
}) {
  const dispatch = useDispatch();
  const [selComp, setSelComp] = useState(userSelComp);
  const onSetCompany = () => {
    setBugsnagUserComp(selComp);
    dispatch(setUserCompany(selComp));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Organization Selector</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={4} maxW={'lg'} rounded={'xl'} p={6}>
            <CompanySelectorInputs
              companies={companies}
              selComp={selComp}
              onSelectedItemChange={setSelComp}
              onSetCompany={onSetCompany}
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
