import { Box, Flex, ListIcon, Text, Tooltip } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import {
  BsFillCheckCircleFill,
  BsFillExclamationCircleFill,
} from 'react-icons/bs';

import { List, ListItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { NAV, TEST_STATUSES } from '../utils/constants';

export default function Segment({
  isCol,
  colId,
  colName,
  onClickAdd,
  disableClick,
  projList,
  highLight,
  selProjId,
}) {
  let projectListContent = null;
  if (!isCol) {
    projectListContent = null;
  } else if (!projList || projList.length === 0) {
    projectListContent = <p>no projects found</p>;
  } else {
    projectListContent = projList.map(proj => {
      let icon = null;
      if (
        proj.overAllStatus === TEST_STATUSES.SUCCESS ||
        proj.overAllStatus === TEST_STATUSES.FAILED
      ) {
        icon = (
          <ListIcon
            h={4}
            as={
              proj.overAllStatus === TEST_STATUSES.SUCCESS
                ? BsFillCheckCircleFill
                : BsFillExclamationCircleFill
            }
            color={
              proj.overAllStatus === TEST_STATUSES.SUCCESS
                ? 'green.500'
                : 'red.500'
            }
            marginEnd={'0.3rem'}
          />
        );
      }
      return (
        <ListItem
          key={proj.id}
          // borderWidth="1px"
          // borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          // bg="brand.100"
          alignItems="center"
          as={Link}
          color={selProjId === proj.id ? 'cyan.900' : ''}
          fontWeight={selProjId === proj.id ? 'bold' : 'normal'}
          to={`/collections/${colId}/projects/${proj.id}/tests`}
          // to={`/collections/${colId}`}
          maxW="md"
          noOfLines={1}
          pl={3}
          _hover={{
            bg: 'gray.100',
          }}
        >
          <Flex justify="space-between" alignItems="center">
            <Text maxW={36} noOfLines={1}>
              {proj.name}
            </Text>

            {icon}
          </Flex>
        </ListItem>
      );
    });
  }
  let heading = isCol ? (
    <Box
      mt="1"
      fontWeight="semibold"
      as={Link}
      to={`/collections/${colId}`}
      lineHeight="tight"
      isTruncated
      _hover={{
        textDecoration: 'underline',
      }}
    >
      {colName}
    </Box>
  ) : (
    <Box mt="1" fontWeight="normal" lineHeight="base" isTruncated>
      {colName}
    </Box>
  );
  return (
    <Box
      borderWidth={highLight ? '2px' : '1px'}
      borderRadius="lg"
      borderColor={highLight ? 'cyan.600' : 'gray.200'}
      // overflow="hidden"
      className={highLight ? NAV.ACTIVE_ITEM : ''}
      p={4}
    >
      <Box
        display="flex"
        mt="2"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        {heading}

        <Tooltip label={`Create project`}>
          <IconButton
            onClick={onClickAdd}
            disabled={disableClick}
            aria-label="Create Collection"
            size="xs"
            isRound
            icon={<FiPlus />}
          />
        </Tooltip>
      </Box>
      <List
        my={3}
        // ml={3}
        spacing={3}
      >
        {projectListContent}
      </List>
    </Box>
  );
}
