import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Alert,
  AlertIcon,
  AlertDescription,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { selectUserInfo } from '../features/user/userSelectors';
import { createUser } from '../features/user/userSlice';
import { USER_ROLES_LIST_OPTIONS, ACCOUNT_TYPES } from '../utils/constants';
import { validateEmail } from '../utils/general';
import { getReactSelectTheme, stylesSize } from '../utils/react-select-styles';

const validateForm = state =>
  state.fullName === '' ||
  state.email === '' ||
  state.newPassword === '' ||
  !state.selComp ||
  !state.selRole
    ? false
    : true;

export default function CreateUserModal({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const [formState, setFormState] = useState({
    fullName: '',
    email: '',
    newPassword: '',
    selComp: undefined,
    selRole: undefined,
  });

  const toast = useToast();

  const onChangeInput = e => {
    const { name, value } = e.target;
    setFormState(oldState => ({
      ...oldState,
      [name]: value,
    }));
  };

  const onChangeOrg = value =>
    setFormState(oldState => ({
      ...oldState,
      selComp: value,
    }));
  const onChangeRole = value =>
    setFormState(oldState => ({
      ...oldState,
      selRole: value,
    }));

  const onSubmit = e => {
    e.preventDefault();
    const requestBody = {
      accountType: ACCOUNT_TYPES.EMAIL,
      companies: [
        {
          id: formState.selComp.id,
        },
      ],
      email: formState.email,
      fullName: formState.fullName,
      // id: 0,
      // mobileNumber: 'string',
      password: formState.newPassword,
      principalName: formState.email,
      roles: [formState.selRole],
    };

    dispatch(createUser(requestBody))
      .then(() => {
        setFormState({
          fullName: '',
          email: '',
          newPassword: '',
          selComp: undefined,
          selRole: undefined,
        });
      })
      .then(onClose)

      .catch(e =>
        toast({
          title: e.message,
        })
      );
  };

  const { fullName, email, newPassword, selComp, selRole } = formState;

  const isValidEmail = validateEmail(email);

  const isEmailErr = !!email && !isValidEmail;

  const isDisableForm = !validateForm(formState) || !isValidEmail;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>Create User</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isRequired>
              <FormLabel htmlFor="fullName">Full Name</FormLabel>
              <Input
                id="fullName"
                name="fullName"
                placeholder="Full Name"
                value={fullName}
                onChange={onChangeInput}
                required
              />
            </FormControl>
            <FormControl mt={3} isInvalid={isEmailErr} isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                value={email}
                onChange={onChangeInput}
                required
              />
              {isEmailErr ? (
                <FormErrorMessage>invalid email.</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl mt={3} isRequired>
              <FormLabel htmlFor="newPassword">Password</FormLabel>
              <Input
                id="newPassword"
                name="newPassword"
                placeholder="Password"
                value={newPassword}
                onChange={onChangeInput}
                required
              />
            </FormControl>
            <FormControl mt={3} isRequired>
              <FormLabel mb={2} htmlFor="addUserComp">
                Choose preferred organization
              </FormLabel>
              <ReactSelect
                inputId="addUserComp"
                placeholder="Type a Organization Name"
                options={userInfo?.userRes?.companies || []}
                getOptionValue={comp => comp.id}
                getOptionLabel={comp => comp.companyName}
                value={selComp}
                onChange={onChangeOrg}
                theme={getReactSelectTheme}
                styles={stylesSize}
                isSearchable
              />
            </FormControl>

            <FormControl mt={3} isRequired>
              <FormLabel mb={2} htmlFor="addUserRole">
                User Role
              </FormLabel>
              <ReactSelect
                inputId="addUserRole"
                placeholder="Type a User Role"
                options={USER_ROLES_LIST_OPTIONS}
                getOptionValue={comp => comp.id}
                getOptionLabel={comp => comp.roleName}
                value={selRole}
                onChange={onChangeRole}
                styles={stylesSize}
                theme={getReactSelectTheme}
                isSearchable
              />
            </FormControl>

            {userInfo.errCreatingUser ? (
              <Alert status="error" mt={3} mb={3}>
                <AlertIcon />
                <AlertDescription>{userInfo.errCreatingUser}</AlertDescription>
              </Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} disabled={userInfo.isCreatingUser}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              mr={3}
              type="submit"
              isLoading={userInfo.isCreatingUser}
              disabled={isDisableForm}
            >
              Create User
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
