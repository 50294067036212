import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Center,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Segment from '../components/Segment';
import { selectCollections } from '../features/collection/collectionSelectors';
import { selectUser } from '../features/user/userSelectors';
import { NAV } from '../utils/constants';
import { isSuperAdmin, scrollByRef } from '../utils/general';
import CreateProjectModal from './CreateProjectModal';

export default function ProjectListNav() {
  const user = useSelector(selectUser);
  const isSuperAdminRole = isSuperAdmin(user?.curRole || {});
  // require for creating project
  const [selColForCreateProj, setSelColForCreateProj] = useState(null);

  const { collections, errFetchCollections, isCollectionFetching } =
    useSelector(selectCollections);

  const createProjModalConf = useDisclosure();

  const colId = +useParams().colId;
  const projId = +useParams().projId;

  useEffect(() => {
    if (!collections.length || !Number.isInteger(colId)) {
      return;
    }
    setTimeout(() => {
      scrollByRef(NAV.LIST, NAV.ACTIVE_ITEM);
    });
  }, [collections, colId]);

  const onClickAddProject = (id, name) => {
    setSelColForCreateProj({
      id,
      name,
    });
    createProjModalConf.onOpen();
  };

  let content = null;

  if (isCollectionFetching) {
    content = (
      <Center m={3}>
        <Spinner color="brand.500" />
      </Center>
    );
  }

  if (errFetchCollections) {
    content = (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{errFetchCollections}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <Box
        overflowY="scroll"
        className={collections.length ? 'scroll_box_shadow' : ''}
        overflowX="hidden"
        h={`calc(100vh - ${isSuperAdminRole ? '240px' : '160px'})`} // HEADER_HEIGHT + ShortCutListItem
        id={NAV.LIST}
      >
        {content
          ? content
          : collections.map(({ id, name, projects }) => (
              <Segment
                highLight={colId === id}
                selProjId={projId}
                key={id}
                colName={name}
                projList={projects}
                colId={id}
                isCol
                onClickAdd={() => onClickAddProject(id, name)}
              />
            ))}
      </Box>
      <CreateProjectModal
        collection={selColForCreateProj}
        isOpen={createProjModalConf.isOpen}
        onClose={createProjModalConf.onClose}
      />
    </>
  );
}
