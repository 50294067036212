import cookie from 'js-cookie';
import { COMPANY_ID_KEY, HEADER_KEYS } from '../utils/constants';
import { devLog } from '../utils/general';

/**
 * Get the access_token
 */
export const getAccessToken = () => cookie.get(HEADER_KEYS.ACCESS_TOKEN_KEY);
/**
 * Get the refresh_token
 */
export const getRefreshToken = () => cookie.get(HEADER_KEYS.REFRESH_TOKEN_KEY);
/**
 * Checks that the user is logged in
 */
export const hasToken = () => !!getAccessToken();

/**
 * @description get Pre Auth Header (Basic)
 * @returns {Headers}
 */
export const getPreAuthHeader = () => {
  // const encoded = Buffer.from(
  //   `${HEADER_KEYS.CLIENT_ID}:${HEADER_KEYS.SECRET_KEY}`
  // ).toString('Base64');
  const encoded = btoa(`${HEADER_KEYS.CLIENT_ID}:${HEADER_KEYS.SECRET_KEY}`);
  return new Headers({
    Authorization: `Basic ${encoded}`,
  });
};
/**
 * @description set access and refresh tokens
 * @param {Object} auth authentication response
 * @param {Boolean} isRefresh refresh flag
 */
export const setAuthTokens = (auth, isRefresh) => {
  const defaultExpireIn = isRefresh ? 24 * 60 * 60 : 60 * 60;
  const expires = (auth.expires_in || defaultExpireIn) * 1000;
  // eslint-disable-next-line camelcase
  const expires_in = new Date(new Date().getTime() + expires);
  // eslint-disable-next-line camelcase
  devLog(`expires: ${expires}`, `Refresh expires on ${expires_in}`);
  cookie.set(HEADER_KEYS.ACCESS_TOKEN_KEY, auth.access_token, {
    expires: expires_in,
  }); // , domain: COOKIE_DOMAIN
  cookie.set(HEADER_KEYS.REFRESH_TOKEN_KEY, auth.refresh_token);
};
export const setCompanyId = companyId =>
  cookie.set(COMPANY_ID_KEY, JSON.stringify(companyId));

export const getCompanyId = () => cookie.get(COMPANY_ID_KEY);

export const asyncSetCompanyId = company =>
  new Promise(resolve => {
    setCompanyId(company.id);
    resolve(company);
  });

/* istanbul ignore next */
/**
 * Log out the current user (deletes cookies)
 */
export const logout = () => {
  cookie.remove(HEADER_KEYS.ACCESS_TOKEN_KEY);
  cookie.remove(HEADER_KEYS.REFRESH_TOKEN_KEY);
  localStorage.clear();
};
/**
 * @description remove token from cookie and clear localStorage
 */
export const asyncLogout = () =>
  new Promise(resolve => {
    logout();
    resolve();
  });

/* istanbul ignore next */
export const logoutAndRedirect = () => {
  logout();
  window.location.pathname = '/login';
};
