export const getReactSelectTheme = theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#3182ce',
    },
  };
};

export const stylesSize = {
  placeholder: base => ({
    ...base,
    color: '#a0aec0',
  }),
  control: base => ({
    ...base,
    borderColor: '#e2e8f0',
  }),
};

export const stylesSizeSm = {
  // menu: base => ({
  //   ...base,
  //   position: 'fixed',
  //   width: '200px',
  //   top: undefined,
  //   // margin: "0",
  //   // top: "12rem",
  //   // bottom: "-10px",
  //   marginTop: '6px',
  //   // background: 'white',
  //   // border: '1px solid black',
  // }),
  control: base => ({
    ...base,
    minHeight: 32,
    borderColor: '#e2e8f0',
    borderRadius: '2px',
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: base => ({
    ...base,
    padding: 4,
  }),
  valueContainer: base => ({
    ...base,
    padding: '0px 6px',
  }),
  input: base => ({
    ...base,
    margin: 0,
    padding: 0,
    width: 100,
    paddingLeft: '2px',
  }),
};
