import React from 'react';
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
} from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import BelowViewPort from './molecules/BelowViewPort';
import AppHeader from './components/AppHeader';
import SidebarContent from './components/SidebarContent';
import {
  HEADER_HEIGHT,
  SIDE_BAR_CLOSED_WIDTH,
  SIDE_BAR_OPENED_WIDTH,
} from './utils/constants';

export default function AppLayout() {
  const mobileDrawerState = useDisclosure();
  const deskDrawer = useDisclosure({ defaultIsOpen: true });
  return (
    <Box
      // minH={`calc(100vh - var(--chakra-sizes-${HEADER_HEIGHT}))`}
      bg={useColorModeValue('gray.100', 'gray.900')}
    >
      {/* header */}
      <AppHeader onOpen={mobileDrawerState.onOpen} />
      {/* For Mobile View */}
      <Drawer
        autoFocus={false}
        isOpen={mobileDrawerState.isOpen}
        placement="left"
        onClose={mobileDrawerState.onClose}
        returnFocusOnClose={false}
        onOverlayClick={mobileDrawerState.onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onCloseMobileDrawer={mobileDrawerState.onClose}
            deskDrawer={deskDrawer}
          />
        </DrawerContent>
      </Drawer>
      {/* container */}
      <Box mt={HEADER_HEIGHT} display="flex">
        {/* For Desktop View */}
        <SidebarContent
          onCloseMobileDrawer={mobileDrawerState.onClose}
          display={{ base: 'none', md: 'block' }}
          deskDrawer={deskDrawer}
        />
        {/* For Desktop View */}
        <Box
          p="6"
          as="main"
          flexGrow={1}
          maxW={`calc(100vw - var(--chakra-sizes-${
            deskDrawer.isOpen ? SIDE_BAR_OPENED_WIDTH : SIDE_BAR_CLOSED_WIDTH
          })))`}
          overflowY="scroll"
          height={`calc(100vh - var(--chakra-sizes-${HEADER_HEIGHT}))`}
        >
          <Outlet />
        </Box>
      </Box>
      {/* keep content in 'BelowViewPort' that will available for all time (all routes) */}
      <BelowViewPort />
    </Box>
  );
}
