import AppRoutes from './AppRoutes';
import AuthHandler from './auth/authHandler';
import AppWatchMan from './components/AppWatchMan';

function App() {
  return (
    <AuthHandler>
      <AppRoutes />
      <AppWatchMan />
    </AuthHandler>
  );
}

export default App;
