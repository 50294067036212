const ErrorView = ({ clearError }) => (
  <div>
    <h2>System error</h2>
    <p color="red.500">
      App is caught some error. Try to go back to previous view by clicking
      reset button below.
    </p>
    <button onClick={clearError}>Reset</button>
  </div>
);
export default ErrorView;
