import HttpReq from '../utils/HttpReq';

const routeName = `/projects`;
export const getTestsApi = id =>
  HttpReq.get(`${routeName}${id ? '/' + id : ''}`);

export const createProjectApi = payload => HttpReq.post(routeName, payload);

export const deleteProjectApi = projectId =>
  HttpReq.delete(`${routeName}/delete`, [projectId], undefined, {
    postJSON: true,
    preJSON: true,
  });

export const forceDeleteProjectApi = projectId =>
  HttpReq.delete(`${routeName}/force-delete`, [projectId], undefined, {
    postJSON: true,
    preJSON: true,
  });
