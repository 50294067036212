import { IconButton, Flex, useColorModeValue, Image } from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import { APP_HEADER_Z_INDEX, HEADER_HEIGHT } from '../utils/constants';
import UserMenu from './UserMenu';
import AppLogo from '../assets/img/turino-logo.png';

export default function AppHeader({ onOpen, ...rest }) {
  return (
    <Flex
      as="header"
      px={{ base: 4, md: 4 }}
      height={HEADER_HEIGHT}
      alignItems="center"
      // bg={useColorModeValue('white', 'gray.900')}
      bg={useColorModeValue('brand.500', 'brand.900')}
      // bg="brand.500"
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="space-between"
      pos="fixed"
      w="100%"
      zIndex={APP_HEADER_Z_INDEX}
      top={0}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
        // colorScheme="whiteAlpha"
        color="white"
      />
      <RouterLink to="/">
        <Image width="100px" src={AppLogo} alt="logo" />
      </RouterLink>
      <UserMenu />
    </Flex>
  );
}
