// import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../features/user/userSelectors';
import { setModalState } from '../features/user/userSlice';
import { MODAL_NAMES } from '../utils/constants';
import UserCompanySelectorModal from './UserCompanySelectorModal';

export default function BelowViewPort() {
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();

  const onCloseCompSelector = () =>
    dispatch(
      setModalState({
        name: MODAL_NAMES.COMPANY_SWITCH,
        isOpen: false,
      })
    );

  if (!userInfo.isLoggedIn) {
    return null;
  }

  return (
    <>
      {userInfo.isFetchingInitialUserInfo ? null : (
        <UserCompanySelectorModal
          isOpen={userInfo[MODAL_NAMES.COMPANY_SWITCH]}
          onClose={onCloseCompSelector}
          companies={userInfo?.userRes?.companies || []}
          userSelComp={userInfo?.user?.curComp}
        />
      )}
    </>
  );
}
