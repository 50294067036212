import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectIsAuthenticated } from '../features/user/userSelectors';

/**
 * @description prevent access with auth state
 */
const NonAuthRoute = props => {
  const { children } = props;
  const isLoggedIn = useSelector(selectIsAuthenticated);
  // const location = useLocation();

  if (isLoggedIn) {
    // const origin = location.state?.from?.pathname || '/';
    const origin = '/';
    return <Navigate to={origin} />;
  }
  return children;
};

export default NonAuthRoute;
