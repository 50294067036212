import { getHigherRole, isSuperAdmin } from '../utils/general';
import HttpReq from '../utils/HttpReq';
import { getCompaniesApi } from './company';

const routeName = `/user`;

export const getUserInfoApi = () => HttpReq.get(`${routeName}/by-jwt`);

export const getFormattedUserInfoApi = async selCompId => {
  const userRes = await getUserInfoApi();
  const { companies: userResCompanies, roles, ...otherUserInfo } = userRes;
  const curRole = getHigherRole(roles || []);
  let curComp;
  let allCompaniesRes;

  const isSuperAdminRole = isSuperAdmin(curRole);

  if (isSuperAdminRole) {
    allCompaniesRes = await getCompaniesApi();
    const selCompIdNum = parseInt(selCompId);
    const selCompFound = allCompaniesRes.find(
      comp => parseInt(comp.id) === selCompIdNum
    );
    if (selCompFound) {
      curComp = selCompFound;
    }
  } else {
    curComp = userResCompanies ? userResCompanies[0] : undefined;
  }

  const user = {
    ...otherUserInfo,
    curComp,
    curRole,
  };
  return {
    userRes: {
      ...userRes,
      companies: isSuperAdminRole ? allCompaniesRes : userResCompanies,
    },
    user,
  };
};

export const getLinkedCompaniesApi = () =>
  HttpReq.get(`${routeName}/getLinkedCompanies`);

export const createUserApi = payload =>
  HttpReq.post(`${routeName}/createUser`, payload);
