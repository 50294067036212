import {
  // IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  // FiBell,
  FiChevronDown,
} from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUserInfo, setModalState } from '../features/user/userSlice';
import { selectUser } from '../features/user/userSelectors';
import { MODAL_NAMES } from '../utils/constants';
import { clearBugsnagAllUserInfo } from '../utils/general';

const UserMenu = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const onLogOut = () => {
    clearBugsnagAllUserInfo();
    dispatch(logOutUserInfo());
  };
  const onSwitchCompany = () =>
    dispatch(
      setModalState({
        name: MODAL_NAMES.COMPANY_SWITCH,
        isOpen: true,
      })
    );
  return (
    <HStack spacing={{ base: '0', md: '6' }}>
      {/* Notification */}
      {/* <IconButton
        color="white"
        size="lg"
        variant="ghost"
        aria-label="open menu"
        icon={<FiBell />}
      /> */}
      {/* Notification */}
      <Flex alignItems={'center'}>
        <Menu>
          <MenuButton
            py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: 'none' }}
          >
            <HStack>
              <Avatar size={'sm'} name={user?.fullName} />
              <VStack
                display={{ base: 'none', md: 'flex' }}
                alignItems="flex-start"
                spacing="1px"
                ml="2"
              >
                <Text fontSize="sm" color="white">
                  {user?.fullName}
                </Text>
                <Text fontSize="xs" color="whiteAlpha.700">
                  {user?.curRole?.roleName}
                </Text>
              </VStack>
              <Box display={{ base: 'none', md: 'flex' }}>
                <FiChevronDown color="white" />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList
            bg={useColorModeValue('white', 'gray.900')}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            zIndex={2}
          >
            <MenuItem>Profile</MenuItem>
            {/* <MenuItem as={Link} to={'/projects'}>
              Projects
            </MenuItem>
            <MenuItem as={Link} to={'/teams'}>
              Teams
            </MenuItem> */}
            <MenuItem onClick={onSwitchCompany}>Switch Organization</MenuItem>
            <MenuItem>Settings</MenuItem>
            <MenuItem>Projects</MenuItem>
            <MenuDivider />
            <MenuItem onClick={onLogOut}>Sign out</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </HStack>
  );
};

export default UserMenu;
