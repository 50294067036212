import { combineReducers } from '@reduxjs/toolkit';
import userInfo from '../features/user/userSlice';
import collection from '../features/collection/collectionSlice';
import test from '../features/test/testSlice';
import ui from '../features/ui/uiSlice';

export default combineReducers({
  ui,
  collection,
  test,
  userInfo,
});
