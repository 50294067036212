import {
  Box,
  CloseButton,
  useColorModeValue,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import SidebarShortcuts from '../molecules/SideBarShortcuts';
import ProjectListNav from '../molecules/ProjectListNav';
import {
  HEADER_HEIGHT,
  SIDE_BAR_CLOSED_WIDTH,
  SIDE_BAR_OPENED_WIDTH,
} from '../utils/constants';

const SidebarContent = ({ onCloseMobileDrawer, deskDrawer, ...rest }) => {
  return (
    <Box
      as="aside"
      transition={deskDrawer.isOpen ? '' : '1s ease'}
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{
        base: 'full',
        md: deskDrawer.isOpen ? SIDE_BAR_OPENED_WIDTH : SIDE_BAR_CLOSED_WIDTH,
      }}
      px={2}
      h={
        deskDrawer.isOpen
          ? 'full'
          : `calc(100vh - var(--chakra-sizes-${HEADER_HEIGHT}))`
      }
      position="relative"
      borderRightWidth={2}
      _hover={{
        borderRightColor: 'brand.400',
      }}
      {...rest}
    >
      <CloseButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onCloseMobileDrawer}
      />
      <Tooltip label="toggle side bar">
        <IconButton
          display={{ base: 'none', md: 'flex' }}
          className="sb-collapse-btn"
          size="xs"
          aria-label={'toggle left side drawer'}
          colorScheme="gray"
          color="black"
          variant="solid"
          isRound
          shadow="dark-lg"
          icon={deskDrawer.isOpen ? <AiOutlineLeft /> : <AiOutlineRight />}
          onClick={() =>
            deskDrawer.isOpen ? deskDrawer.onClose() : deskDrawer.onOpen()
          }
          _hover={{
            color: 'white',
            colorScheme: 'brand',
            bg: 'brand.600',
          }}
          top={'6rem'}
          left={deskDrawer.isOpen ? '14.2rem' : '1.4rem'}
          position="absolute"
          transition={deskDrawer.isOpen ? '' : '1s ease'}
        />
      </Tooltip>
      <Box display={deskDrawer.isOpen ? 'block' : 'none'}>
        <SidebarShortcuts />
        <ProjectListNav />
      </Box>
    </Box>
  );
};

export default SidebarContent;
