import Bugsnag from '@bugsnag/js';
import {
  MSG_APP_KEY,
  TEST_STATUSES,
  URL_PROTOCOL_HTTP,
  URL_PROTOCOL_HTTPS,
  URL_REGEX,
  SOURCE_TYPES,
  USER_ROLES_LIST,
  USER_ROLES,
  EMAIL_REGEX,
  BugsnagMetaKeys,
} from './constants';
import papaparse from 'papaparse';

const { href: curHref } = window.location;

export const generateActionPayload = (action, payload, from = curHref) => ({
  src: MSG_APP_KEY,
  action,
  payload,
  from,
});

/**
 * @description send window (page) message
 * @param {String} action action
 * @param {Any} payload anything
 */
export const sendWindowMsg = (action, payload) => {
  // setting current href as origin to sending message to current domain (content script) only
  // window.postMessage(generateActionPayload(action, payload), curHref);
  window.postMessage(generateActionPayload(action, payload));
};

export const isUrl = text => {
  const result = text.match(URL_REGEX);
  return Array.isArray(result) && result.length === 1;
};

export const devLog = (...params) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(...params);
  }
};
export const errorLog = (...params) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(...params);
  }
  if (process.env.NODE_ENV !== 'development') {
    Bugsnag.notify(...params);
  }
};

export const reportPluginErr = (msg, detail) => {
  const { test, ...pluginInfo } = detail;
  Bugsnag.notify(new Error(msg), event => {
    event.appType = detail.appType;
    event.context = 'plugin-error';
    event.addMetadata('plugin', {
      ...pluginInfo,
    });
    event.addMetadata('test', {
      ...test,
    });
  });
};

/**
 * @description remove id, text from object
 */
export const createStepPayload = ({ text, id, ...payload }) => payload;

export const wordCount = (word, count) => `${word}${count > 1 ? 's' : ''}`;

export const savePageInfo = (page, config) => {
  const lsStr = localStorage.getItem('ihiLs') || '{}';
  const obj = JSON.parse(lsStr);
  if (!obj.tableConfig) {
    obj.tableConfig = {};
  }
  if (!obj.tableConfig[page]) {
    obj.tableConfig[page] = {
      ...config,
    };
  } else {
    obj.tableConfig[page] = {
      ...obj.tableConfig[page],
      ...config,
    };
  }

  localStorage.setItem('ihiLs', JSON.stringify(obj));
};

export const getPageInfo = page => {
  const lsStr = localStorage.getItem('ihiLs') || '{}';
  const obj = JSON.parse(lsStr);
  if (!obj.tableConfig) {
    obj.tableConfig = {};
  }

  if (!obj.tableConfig[page]) {
    return {};
  } else {
    return obj.tableConfig[page];
  }
};

export const generateSteps = (steps, testId) =>
  steps.map(({ id, text, isNew, selOpt, ...rest }) => ({
    ...rest,
    testId,
  }));
export const generateStepsForEdit = (steps, testId) =>
  steps.map(({ id, text, isNew, selOpt, ...rest }) => {
    const st = {
      ...rest,
      testId,
    };
    // add id for existing elements
    if (!isNew) {
      st.id = id;
    }
    return st;
  });

export const scrollByRef = (refId, itemClassName) => {
  var divElement = document.getElementById(refId);
  const itemEl = document.querySelector(`.${itemClassName}`);
  if (divElement && itemEl) {
    divElement.scroll({
      // top: divElement.scrollHeight,//scroll to the bottom of the element
      top: itemEl.offsetTop - 100, //scroll to the bottom of the element
      behavior: 'smooth', //auto, smooth, initial, inherit
    });
  }
};

export const isTestInProg = lastRunStatus =>
  lastRunStatus === TEST_STATUSES.CREATED ||
  lastRunStatus === TEST_STATUSES.IN_PROGRESS ||
  lastRunStatus === TEST_STATUSES.PREPARING ||
  lastRunStatus === TEST_STATUSES.IN_QUEUED
    ? true
    : false;

export const toggleBoolState = flag => !flag;

/**
 * @description get URL column and it's value
 */
export const findUrlColDetail = (csvHeaders, row) => {
  let urlColValToBe = '';
  let testUrlToBe = '';
  const urlIndex = row.findIndex(rowVal =>
    isUrl(prependURLProtocol(rowVal || ''))
  );

  if (urlIndex > -1) {
    urlColValToBe = csvHeaders[urlIndex].trim();
    testUrlToBe = row[urlIndex].trim();
  }

  return {
    urlColValToBe,
    testUrlToBe,
  };
};
/**
 * @description get javascript array from csv file and remove empty rows
 */
export const processTestWebCsv = file =>
  new Promise((res, rej) => {
    papaparse.parse(file, {
      skipEmptyLines: true,
      complete: function (results) {
        const data = results.data.map(row => row.map(c => c.trim()));
        try {
          // remove empty rows (row with every empty column)
          const tableData = data.filter(row => row.findIndex(c => !!c) > -1);
          // throw if csv is empty
          if (!data || !Array.isArray(data) || data.length === 0) {
            rej(new Error('empty csv file'));
          }
          // throw if csv has only headers
          if (data.length === 1) {
            rej(new Error('missing test rows in csv'));
          }
          res(tableData);
        } catch (error) {
          devLog(error);
          rej(error);
        }
      },
      error: function (err, file, inputElem, reason) {
        devLog(err, file, inputElem, reason);
        rej(err);
      },
    });
  });

/**
 * @description get javascript array with URL column and it's value from csv file and remove empty rows
 */
export const getTestsDataFromCsv = async file => {
  try {
    const data = await processTestWebCsv(file);
    const [csvHeaders, firstRow] = data;
    const urlDetail = findUrlColDetail(csvHeaders, firstRow);
    const urlColValToBe = urlDetail.urlColValToBe || csvHeaders[0];
    const testUrlToBe = urlDetail.testUrlToBe || '';
    return {
      data,
      urlColValToBe,
      testUrlToBe,
    };
  } catch (errorLog) {
    devLog(errorLog);
    throw errorLog;
  }
};

/* export const getMatchedTestsDataFromCsv = async (file, state) => {
  try {
    const data = await processTestWebCsv(file);
    const [csvHeaders, firstRow] = data;
    const urlDetail = findUrlColDetail(csvHeaders, firstRow);
    const urlColValToBe = urlDetail.urlColValToBe || csvHeaders[0];
    const testUrlToBe = urlDetail.testUrlToBe || '';
    return {
      data,
      urlColValToBe,
      testUrlToBe,
    };
  } catch (errorLog) {
    devLog(errorLog);
    throw errorLog;
  }
}; */

export const getMappedColumns = state => {
  const mappedCols = state.steps.map(st => st.mappedColumn);
  mappedCols.push(
    state.mappedIdentifierColumnOpt.value,
    state.mappedUrlColumnOpt.value
  );
  const nonEmpty = mappedCols.filter(c => !!c);
  return [...new Set(nonEmpty)];
};

export const mapSourceTestToState = (oldState, source, atConnErr) => {
  const isCsv = source.dataSourceType === 'CSV';
  const csvRows = source?.csv?.csvRows || [[]];
  const newState = {
    ...oldState,
    name: source.name,
    dataSourceType: source.dataSourceType,
    mappedUrlColumnOpt: {
      label: source.mappedUrlColumn,
      value: source.mappedUrlColumn,
    },
    mappedIdentifierColumnOpt: {
      label: source.mappedIdentifierColumn,
      value: source.mappedIdentifierColumn,
    },
    webpageUrl: source.webpageUrl,
    webPageUrlErr: '',
    csv: {
      name: source?.csv?.name || '',
      csvRows,
    },
    // data.airTable doesn't have all information that we get on connect request
    airTableData: isCsv ? undefined : source.airTable,
    isNewFile: false,
    hasStepsChanged: false,
    hasStepsRemoved: false,
    hasStepsLengthChanged: false,
    steps: source.steps.map(st => ({
      ...st,
      selOpt: {
        label: st.mappedColumn,
        value: st.mappedColumn,
      },
    })),
    missedCols: [],
    atUrlErr: '',
    atConnErr: atConnErr || '',
    apiKey: isCsv ? '' : source?.airTable?.apiKey,
    url: isCsv ? '' : source?.airTable?.url,
    captureAllPages: source.captureAllPages ? 'yes' : 'no',
  };

  newState.mappedCols = getMappedColumns(newState);
  const [headerCols] = csvRows;
  newState.missedCols = filterMissedInCapsColNames(
    newState.mappedCols,
    headerCols
  );
  return newState;
};

export const checkHasStepChange = (state, source) => {
  if (
    state.mappedColumn !== source.mappedColumn ||
    state.assertionType !== source.assertionType ||
    state.textComparisonType !== source.textComparisonType ||
    state.caseSensitiveCheck !== source.caseSensitiveCheck ||
    state.ignoreWhiteSpaces !== source.ignoreWhiteSpaces
  ) {
    return true;
  }
  return false;
};

export const checkIfStateChanged = (state, source) => {
  const { apiKey, url } = source.airTable || { url: '', apiKey: '' };

  if (
    state.name !== source.name ||
    state.dataSourceType !== source.dataSourceType ||
    state.mappedUrlColumnOpt.value !== source.mappedUrlColumn ||
    state.mappedIdentifierColumnOpt.value !== source.mappedIdentifierColumn ||
    state.captureAllPages !== (source.captureAllPages ? 'yes' : 'no') ||
    (state.dataSourceType === SOURCE_TYPES.AIR_TABLE &&
      (state.url !== url || state.apiKey !== apiKey))
  ) {
    return true;
  }
  return false;
};

export const validateForm = state => {
  const atErr =
    state.dataSourceType === SOURCE_TYPES.AIR_TABLE &&
    (state.url === '' ||
      state.apiKey === '' ||
      !state.airTableData ||
      state.atConnErr);
  const csvErr =
    state.dataSourceType === SOURCE_TYPES.CSV &&
    state.csv.csvRows[0].length === 0;

  if (
    state.name === '' ||
    atErr ||
    csvErr ||
    state.mappedUrlColumnOpt === undefined ||
    state.mappedIdentifierColumnOpt === undefined ||
    state.steps.length === 0 ||
    !!state.webPageUrlErr
  ) {
    return false;
  }
  return true;
};

export const compareInCaseSensitive = (str1, str2) =>
  str1.localeCompare(str2, undefined, {
    sensitivity: 'base',
  }) === 0;

/**
 * @description prepend https:// url if doesn't exist http:// or https://
 * @param {String} url url
 * @return {String} prepended url
 */
export const prependURLProtocol = url => {
  return url.startsWith(URL_PROTOCOL_HTTPS) || url.startsWith(URL_PROTOCOL_HTTP)
    ? url
    : `${URL_PROTOCOL_HTTPS}${url}`;
};

export const isValidUrl = url => {
  try {
    new URL(url);
    return true;
  } catch (urlErr) {
    devLog(urlErr);
    return false;
  }
};

/**
 * @description get higher role among the user role list
 */
export const getHigherRole = userRoleList => {
  let higherRole;
  for (let index = 0; index < USER_ROLES_LIST.length; index++) {
    const roleInOrder = USER_ROLES_LIST[index];
    higherRole = userRoleList.find(ur => ur.code === roleInOrder);
    if (higherRole) {
      break;
    }
  }
  return higherRole;
};

export const isSuperAdmin = role => role.code === USER_ROLES.SUPER_ADMIN;

export const columnItemToStr = column => column;

export function getColumnFilter(inputValue) {
  return function columnFilter(column) {
    const inputValueLower = (inputValue || '').toLowerCase();
    return !inputValue || column.toLowerCase().includes(inputValueLower);
  };
}

export const companyItemToStr = item => (item ? item.companyName : '');

export function getCompaniesFilter(inputValue) {
  return function companyFilter(company) {
    const inputValueLower = (inputValue || '').toLowerCase();
    return (
      !inputValue || company.companyName.toLowerCase().includes(inputValueLower)
    );
    // !inputValue ||
    // ||
    // company.contactEmail.toLowerCase().includes(inputValue)
  };
}

export const validateEmail = email => EMAIL_REGEX.test(email);

export const filterTests = (tests, filterFn) => tests.filter(filterFn);

export const getTestIdList = tests => tests.map(t => t.id);

export const isTestInProgress = t =>
  t.lastRunStatus === TEST_STATUSES.IN_PROGRESS;

export const isTestInDraft = t => t.lastRunStatus === TEST_STATUSES.DRAFT;

export const isTestInQueueOrProg = t =>
  t.lastRunStatus === TEST_STATUSES.IN_PROGRESS ||
  t.lastRunStatus === TEST_STATUSES.IN_QUEUED;

export const isAllDraft = tests =>
  filterTests(tests, isTestInDraft).length === tests.length;

export const getInProgTestList = tests => filterTests(tests, isTestInProg);
export const getInProgQueuedTestList = tests =>
  filterTests(tests, isTestInQueueOrProg);

export const getInProgTestIdList = tests =>
  getTestIdList(getInProgTestList(tests));

export const getInProgQueuedTestIdList = tests =>
  getTestIdList(getInProgQueuedTestList(tests));

export const getUpdatedTestsByNewStatus = (existingTests, newTestsRes) => {
  const testsToRemove = [...newTestsRes];
  for (let i = 0; i < existingTests.length; i++) {
    const et = existingTests[i];
    const index = testsToRemove.findIndex(ttr => ttr.id === et.id);
    if (index > -1) {
      testsToRemove.splice(index, 1);
    } else {
      newTestsRes.push(et);
    }
  }
  return newTestsRes;
};

export const hasFailedSteps = page => page.stepsFailed > 0;

export const mapCol2Opt = columns => columns.map(c => ({ label: c, value: c }));

/**
 * @description get filename from header string
 */
export const getFileName = str =>
  str.split('filename=')[1].split(';')[0].replace(/['"]/g, '');

export const downloadBlob = async (blob, fileName) => {
  try {
    const newBlob = new Blob([blob]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
  } catch (err) {
    console.log(err);
  }
};

export const toInitialTitle = (str = '') =>
  str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();

export const setBugsnagUserComp = userComp =>
  Bugsnag.addMetadata(BugsnagMetaKeys.curComp, {
    name: userComp.companyName,
    id: userComp.id,
  });

export const setBugsnagUserRole = userRole =>
  Bugsnag.addMetadata(BugsnagMetaKeys.curRole, {
    code: userRole.code,
  });

export const setBugsnagUser = user => {
  Bugsnag.setUser(user.id, user.email, user.fullName);
  if (user.curComp) {
    setBugsnagUserComp(user.curComp);
  }
  setBugsnagUserRole(user.curRole);
};

export const clearBugsnagMeta = () => {
  Bugsnag.clearMetadata(BugsnagMetaKeys.curComp);
  Bugsnag.clearMetadata(BugsnagMetaKeys.curRole);
};

export const clearBugsnagAllUserInfo = () => {
  Bugsnag.setUser('', '', '');
  clearBugsnagMeta();
};

export const filterMissedInCapsColNames = (mappedCols, csvCols) =>
  mappedCols.filter(
    mCol =>
      csvCols.findIndex(newCol => compareInCaseSensitive(mCol, newCol)) === -1
  );

export const findEquivalentInCaseColumnIndex = (newCols, oldColName) =>
  newCols.findIndex(col => compareInCaseSensitive(col, oldColName));

export const findEquivalentInCaseColumn = (newCols, oldColName) => {
  const index = findEquivalentInCaseColumnIndex(newCols, oldColName);
  if (index > -1) {
    return newCols[index];
  }
  return undefined;
};
