import {
  getAccessToken,
  getPreAuthHeader,
  getRefreshToken,
  hasToken,
  logoutAndRedirect,
  setAuthTokens,
} from '../services/Authentication';
import fetchReq, { onHttpReqSuccess } from './FetchReq';
import { devLog } from './general';
import { setInitHeaders, setPostBodyHeaders } from './HttpUtils';
import refreshTokenHolder from './refreshTokenHolder';

const baseUrl = process.env.REACT_APP_ENV_BE_URL;
const refreshTokenPath = 'security/oauth/token';

/**
 * A fetchReq features
 * @typedef {Object} Feature
 * @property {Boolean} features.postJSON parse response in JSON
 * @property {Boolean} features.postText parse response in text
 */

/**
 * @description get Full URL of api by passing segment
 * @param {String} path segment of url
 * @returns {String} full URL
 */
const getFullURL = path => `${baseUrl}${path}`;

export const setAuthHeader = options => {
  // eslint-disable-next-line no-param-reassign
  options = options || {};

  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers();
  }

  if (!options.headers.has('Authorization')) {
    options.headers.set('Authorization', `Bearer ${getAccessToken()}`);
  }
  return options;
};

/**
 * @description Async Http call function
 * @param {String} path segment of URL
 * @param {Object} options Fetch options object
 * @param {Feature} features
 * @returns {Promise}
 */
async function Http(path, options, features) {
  if (refreshTokenHolder.inProgress && !path.includes(refreshTokenPath)) {
    return new Promise(newPromiseRes => {
      refreshTokenHolder.promise.then(async () => {
        const retryRes = await Http(path, options, features);
        newPromiseRes(retryRes);
      });
    });
  }

  const url = getFullURL(path);
  // eslint-disable-next-line no-param-reassign
  options = setAuthHeader(options);
  return fetchReq(url, options, features).catch(error => {
    const retryAfterFail = () => {
      options.headers.set('Authorization', `Bearer ${getAccessToken()}`);
      return fetchReq(url, options, features);
    };

    if (error.status === 401 && window.location.pathname !== '/login') {
      if (!hasToken()) {
        logoutAndRedirect();
      }

      if (refreshTokenHolder.inProgress && refreshTokenHolder.promise) {
        if (url.includes('oauth/token')) {
          devLog('error refresh token', error);
          refreshTokenHolder.inProgress = false;
          logoutAndRedirect();
          throw error;
        } else {
          return refreshTokenHolder.promise.then(retryAfterFail);
        }
      }
      // eslint-disable-next-line no-use-before-define
      refreshTokenHolder.promise = refreshTokenApi().then(refRes => {
        if (refRes) {
          setAuthTokens(refRes);
        }
        refreshTokenHolder.inProgress = false;
        return retryAfterFail();
      });

      refreshTokenHolder.inProgress = true;
      return refreshTokenHolder.promise;
    }
    devLog(error);
    throw error;
  });
}

/**
 * @description Async Http GET call function
 * @param {String} path segment of URL
 * @param {Object} options Fetch options object
 * @param {Feature} features
 * @returns {Promise}
 */
function get(path, options, features) {
  return Http(path, options, features);
}

/**
 * @description Async Http DELETE call function
 * @param {String} path segment of URL
 * @param {Object} options Fetch options object
 * @param {Feature} features
 * @returns {Promise}
 */
function deleteReq(path, payload, options, features) {
  const reqOptions = {
    method: 'DELETE',
    headers: new Headers(),
    body: null,
  };

  setPostBodyHeaders(payload, reqOptions, features);
  setInitHeaders(options, reqOptions);

  return Http(path, reqOptions, features);
}

/**
 * A POST type request features
 * @typedef {Object} PostFeature
 * @property {Boolean} features.postJSON parse response in JSON
 * @property {Boolean} features.postText parse response in text
 * @property {Boolean} features.postURLEnc for URL encoded request
 * @property {Boolean} features.multiPartForm for multipart form request
 * @property {Boolean} features.preJSON for JSON type request
 */

/**
 * @description Async Http POST or PUT/PATCH call function
 * @param {String} path segment of URL
 * @param {FormData|Object} payload request payload
 * @param {Object} options Fetch options object
 * @param {PostFeature} features
 * @param {String} [method="post"] method
 * @returns {Promise}
 */
function post(path, payload, options, features, method = 'post') {
  const reqOptions = {
    method,
    headers: new Headers(),
    body: null,
  };

  const allFeatures = features || {
    // default post feature
    postJSON: true,
    preJSON: true,
  };
  setPostBodyHeaders(payload, reqOptions, allFeatures);
  setInitHeaders(options, reqOptions);
  return Http(path, reqOptions, allFeatures);
}
/**
 * @description Async Http PUT call function
 * @param {String} path segment of URL
 * @param {FormData|Object} payload request payload
 * @param {Object} options Fetch options object
 * @param {PostFeature} features
 * @returns {Promise}
 */
function put(path, payload, options, features) {
  return post(path, payload, options, features, 'put');
}
/**
 * @description Async Http PATCH call function
 * @param {String} path segment of URL
 * @param {FormData|Object} payload request payload
 * @param {Object} options Fetch options object
 * @param {PostFeature} features
 * @returns {Promise}
 */
function patch(path, payload, options, features) {
  return post(path, payload, options, features, 'PATCH');
}
/**
 * @description Async Http query (URL encoded) call function
 * @param {String} path segment of URL
 * @param {Object} payload request payload
 * @param {Feature} features request features
 * @returns {Promise}
 */
async function query(path, payload, options, features) {
  const url = getFullURL(path);
  const reqUrl = new URL(url);
  reqUrl.search = new URLSearchParams(payload);
  // eslint-disable-next-line no-param-reassign
  options = setAuthHeader(options);
  const res = await fetch(url, options);
  return onHttpReqSuccess(res, features);
}
const HttpReq = {
  get,
  post,
  query,
  put,
  patch,
  delete: deleteReq,
};

/**
 * @description get latest Access/Refresh tokens and save it to LocalStorage
 * @return {Promise}
 */
export const refreshTokenApi = () => {
  const payload = {
    grant_type: 'refresh_token',
    refresh_token: getRefreshToken(),
  };
  const config = {
    headers: getPreAuthHeader(),
  };
  return HttpReq.post(`/${refreshTokenPath}`, payload, config, {
    multiPartForm: true,
    postJSON: true,
  });
};

export default HttpReq;
