import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCollectionsApi,
  createCollectionApi,
} from '../../services/collection';

const initialState = {
  collections: [],
  isCollectionFetching: false,
  errFetchCollections: '',
  isCreatingCollection: false,
  errCreatingCollection: '',
};

const sliceName = `collection`;

export const getCollections = createAsyncThunk(
  `${sliceName}/getAllCollections`,
  getCollectionsApi
);

export const createCollection = createAsyncThunk(
  `${sliceName}/createCollection`,
  async payload => createCollectionApi(payload)
);

export const counterSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCollections(state, { payload }) {
      state.collections = payload;
    },
    addProjectToCollection(state, { payload }) {
      const { colId, project } = payload;
      const foundColId = state.collections.findIndex(c => c.id === colId);
      if (foundColId > -1) {
        if (!state.collections[foundColId].projects) {
          state.collections[foundColId].projects = [project];
        } else {
          state.collections[foundColId].projects.push(project);
        }
      }
    },
    removeProject(state, { payload }) {
      const { colId, projId } = payload;
      const foundColId = state.collections.findIndex(c => c.id === colId);
      if (foundColId > -1) {
        state.collections[foundColId].projects = state.collections[
          foundColId
        ].projects.filter(p => p.id !== projId);
      }
    },
    removeCollection(state, { payload }) {
      state.collections = state.collections.filter(c => c.id !== payload);
    },
  },
  extraReducers: {
    // get collection
    [getCollections.fulfilled]: (state, { payload }) => {
      if (payload && Array.isArray(payload)) {
        state.collections = payload;
      }
      state.isCollectionFetching = false;
    },
    [getCollections.pending]: state => {
      state.isCollectionFetching = true;
    },
    [getCollections.rejected]: (state, action) => {
      state.errFetchCollections = action?.error?.message || '';
      state.isCollectionFetching = false;
    },
    // create collection
    [createCollection.fulfilled]: (state, { payload }) => {
      if (payload) {
        payload.projects = payload.projects || [];
        state.collections.push(payload);
      }
      state.isCreatingCollection = false;
    },
    [createCollection.pending]: state => {
      state.isCreatingCollection = true;
    },
    [createCollection.rejected]: (state, action) => {
      state.errCreatingCollection = action?.error?.message || '';
      state.isCreatingCollection = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCollections,
  addProjectToCollection,
  removeProject,
  removeCollection,
} = counterSlice.actions;

export default counterSlice.reducer;
