import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addProjectToCollection } from '../features/collection/collectionSlice';
import { createProjectApi } from '../services/project';

export default function CreateProjectModal({ isOpen, onClose, collection }) {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    error: '',
    isCreating: false,
  });

  const toast = useToast();
  const onSubmit = async e => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const requestBody = {
      teamId: collection.id,
      name: fd.get('name'),
      description: fd.get('desc'),
      // code: fd.get('code'),
    };

    setFormState({
      isCreating: true,
      error: '',
    });

    createProjectApi(requestBody)
      .then(newProj => {
        dispatch(
          addProjectToCollection({
            colId: collection.id,
            project: newProj,
          })
        );
        toast({
          title: 'project created',
          status: 'success',
        });
        setFormState({
          isCreating: false,
          error: '',
        });
        onClose();
      })
      .catch(e => {
        toast({
          title: e.message,
          status: 'error',
        });
        setFormState({
          isCreating: false,
          error: e.message,
        });
      });
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>Create Project of {collection?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                // ref={initialRef}
                id="name"
                name="name"
                placeholder="Name"
                required
              />
            </FormControl>
            {/* <FormControl mt={3}>
              <FormLabel htmlFor="code">Code</FormLabel>
              <Input
                id="code"
                name="code"
                placeholder="Code"
                required
              />
            </FormControl> */}
            <FormControl mt={3}>
              <FormLabel htmlFor="desc">Description</FormLabel>
              <Input
                // ref={initialRef}
                id="desc"
                name="desc"
                placeholder="Description"
                required
              />
            </FormControl>
            {formState.error ? (
              <Alert status="error" mt={3} mb={3}>
                <AlertIcon />
                <AlertDescription>{formState.error}</AlertDescription>
              </Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} disabled={formState.isCreating}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              mr={3}
              type="submit"
              isLoading={formState.isCreating}
            >
              Create Project
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
