import { LS_KEY, WIN_MSG_ACTIONS } from './constants';
import { sendWindowMsg } from './general';

export const requestPluginInfo = () => {
  sendWindowMsg(WIN_MSG_ACTIONS.TAB_PLUGIN_INFO_REQ);
};

export const saveTestSuite = tSuite =>
  localStorage.setItem(LS_KEY.testSuite, JSON.stringify(tSuite));

export const asyncSaveTestSuite = tSuite =>
  new Promise(res => {
    saveTestSuite(tSuite);
    res(tSuite);
  });

export const removeTestSuite = () => localStorage.removeItem(LS_KEY.testSuite);

export const asyncRemoveTestSuite = () =>
  new Promise(res => {
    removeTestSuite();
    res(true);
  });

export const getTestSuite = () => {
  const ls = localStorage.getItem(LS_KEY.testSuite);
  return ls ? JSON.parse(ls) : null;
};

export const asyncGetTestSuite = () =>
  new Promise(res => {
    res(getTestSuite());
  });

export const openSelection = payload =>
  sendWindowMsg(WIN_MSG_ACTIONS.START_SELECTION, payload);

export const warnEditDiscard = payload =>
  sendWindowMsg(WIN_MSG_ACTIONS.EDIT_CANCELLED, payload);
