import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { store } from './store';
import { Provider } from 'react-redux';
import { theme } from './theme';
import './index.css';
import packageJson from '../package.json';
import PlainErrorView from './components/PlainErrorView';
import { errorLog } from './utils/general';
import { APP_TYPE } from './utils/constants';

const root = document.getElementById('root');
root.dataset.ihiBuild = packageJson.buildindex;
const bsRelease = process.env.REACT_APP_ENV_BS_RELEASE;
const isLocal = bsRelease === false;

Bugsnag.start({
  appType: APP_TYPE,
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  autoDetectErrors: !isLocal,
  autoTrackSessions: !isLocal,
  plugins: [new BugsnagPluginReact()],
  releaseStage: bsRelease,
  appVersion: process.env.REACT_APP_VERSION,
  enabledReleaseStages: ['production', 'dev'],
  redactedKeys: [
    'access_token', // exact match: "access_token"
    /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
    /^cc_/, // prefix match: "cc_number" "cc_cvv" "cc_expiry"
  ],
  metadata: {
    buildInfo: {
      buildIndex: packageJson.buildindex,
    },
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={PlainErrorView} onError={errorLog}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ChakraProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
