import { Button, FormLabel } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { getReactSelectTheme } from '../utils/react-select-styles';

export default function CompanySelectorInputs({
  companies,
  selComp,
  onSelectedItemChange,
  onSetCompany,
}) {
  return (
    <>
      <FormLabel mb={0} htmlFor="compSelectorUser">
        Choose preferred organization
      </FormLabel>
      <ReactSelect
        inputId="compSelectorUser"
        placeholder="Type a Organization Name"
        options={companies}
        getOptionValue={comp => comp.id}
        getOptionLabel={comp => comp.companyName}
        value={selComp}
        onChange={onSelectedItemChange}
        theme={getReactSelectTheme}
        isSearchable
      />
      <Button
        bg={'blue.400'}
        color={'white'}
        _hover={{
          bg: 'blue.500',
        }}
        type="button"
        onClick={onSetCompany}
        disabled={!selComp}
      >
        Continue
      </Button>
    </>
  );
}
